import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { HContainer, Layout, Seo, TextGroup } from "components/common"
import Footer from "components/navigation/Footer"

import Nav from "components/navigation/Nav"
import MobileNav from "src/components/navigation/mobile/MobileNav"

import { Posts } from "src/components/post"

// import { NewsPosts } from "src/components/news"

// import NewsLetterForm from "components/forms/NewsLetterForm"

import BottomNewsletterCta from "src/components/common/shared/BottomNewsletterCta"

import { device, useContentfulSection, useMeta } from "utils"

const ArticlePage = ({ data: { learn, articles, bottom_cta } }) => {
  const { learn_title } = useContentfulSection(learn.sections)

  const meta = useMeta(learn)

  const posts = {
    edges: [...articles.edges].sort(
      (a, b) => (new Date(a.node.date)).valueOf() - (new Date(b.node.date)).valueOf()
    )
  };

  return (
    <Layout>
      <Seo {...meta} />

      <Nav />
      <MobileNav />

      <Wrap>
        <HContainer className="learn-container">
          <TextGroup
            className="learn-title"
            title={learn_title.title}
            titleAs="h1"
            subtitle={learn_title.subtitle}
          />

          {/*  posts */}
          <Posts posts={posts} type="learn" />

          <BottomNewsletterCta {...bottom_cta} />
        </HContainer>
      </Wrap>
      <Footer />
    </Layout>
  )
}

const Wrap = styled.div`
  position: relative;
  z-index: 1;
  min-height: calc(var(--vh, 1vh) * 100);

  .learn-title {
    --tg-text-align: center;
  }

  .learn-container {
    /* --section-max-width: 1080px; */
    --section-max-width: 925px;
    padding-top: var(--sp-mobile-nav);
    padding-bottom: var(--sp-section-mobile);

    @media ${device.laptop} {
      padding-top: var(--sp-nav);
      padding-bottom: var(--sp-section);
    }

    .posts {
      margin-top: var(--sp-64);
    }
  }
`

export const query = graphql`
  {
    learn: contentfulPage(pageId: { eq: "learn" }) {
      ...Page
    }
    articles: allContentfulArticle(
      filter: { slug: { ne: "placeholder" } }
      sort: { order: DESC, fields: date } #   limit: 9
    ) {
      edges {
        node {
          ...Article
        }
      }
    }
    bottom_cta: contentfulSection(sectionId: { eq: "bottom_newsletter_cta" }) {
      ...Section
    }
  }
`

export default ArticlePage
